<template>
  <div>
    <div class="mainContent">
      <a-form-model :layout="layout" style="width:100%;" ref="ruleForm" :model="form">
        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="网站域名" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="es_hosts">
            <a-input v-model="form.domain" placeholder="请输入网站域名..." />
          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="显示网站" :labelCol='labelcol' :wrapperCol="wrappercol" prop="domain_type">
            <a-select v-model="form.domain_type" placeholder="请选择订单状态..." :options='domainTypeList'>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="日期" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="es_hosts">
            <a-date-picker placeholder="选择日期" v-model="form.date" @change="onChangeDate" format="YYYY-MM-DD" />
          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="12" :lg="8" :xl="6" class="lyq_flex_grow">
          <a-form-model-item style="text-align:right;">

            <a-button type="primary" @click="search">
              搜索
            </a-button>
            <a-button @click="reset('ruleForm')">重置</a-button>

          </a-form-model-item>
        </a-col>
      </a-form-model>
    </div>

    <div class="mainContent" >

      <div class="tableHeader">
        <div class="title">查询表格
          <div style="display:inline-block" v-if="res_all.length>0">
            <span style="margin:0 30px;font-size: 14px;color:#248067">今天总IP：{{res_all[0].ip_count_all}}&nbsp;&nbsp;&nbsp;总PV：{{res_all[0].pv_count_all}}</span>&nbsp;/
            <span style="margin:0 30px;font-size: 14px;color:#248067">昨天总IP：{{res_all[1].ip_count_all}}&nbsp;&nbsp;&nbsp;总PV：{{res_all[1].pv_count_all}}</span>/
            <span style="margin:0 30px;font-size: 14px;color:#248067">前天总IP：{{res_all[2].ip_count_all}}&nbsp;&nbsp;&nbsp;总PV：{{res_all[2].pv_count_all}}</span>
          </div>
        </div>
        <div class="operate">

          <a-tooltip placement="top">
            <template slot="title">
              <span>下载</span>
            </template>
            <a class="ant-dropdown-link" style="display:block;margin-right:10px;" @click="start" :disabled="!selectedRowKeys.length > 0">
              <a-icon type="vertical-align-bottom" />
            </a>
          </a-tooltip>

          <a-tooltip placement="top">
            <template slot="title">
              <span>表格大小</span>
            </template>
            <a-dropdown :trigger="['click']" style="margin-right:10px;">
              <a class="ant-dropdown-link" style="display:block">
                <a-icon type="column-height" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a @click="tableSize='default'">默认</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="tableSize='middle'">中等</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="tableSize='small'">紧凑</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </a-tooltip>

        </div>
      </div>

      <a-table
          :size="tableSize"
          :scroll="{ x: true }"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          :columns="columns"
          :row-key="record => record.site_id"
          :data-source="data"
          :pagination="pagination"
          :loading="loading"
          @change="handleTableChange"
          bordered
      >
        <!-- 备注 -->
        <template slot="备注" slot-scope="data,record">
          <span style="display:block">{{record.remark}}</span>
          <a-button size="small" type="primary" @click="onEdit(record.remark,record.site_id)">编辑</a-button>
        </template>
        <!-- 状态单元格 -->
        <template slot="IP" slot-scope="data,record">
            今日：{{record.statistics.today.ip_count}}<br>
            昨日：{{record.statistics.yesterday.ip_count}}
        </template>

        <template slot="PV" slot-scope="data,record">
          今日：{{record.statistics.today.pv_count}}<br>
          昨日：{{record.statistics.yesterday.pv_count}}
        </template>

        <template slot="UV" slot-scope="data,record">
          今日：{{record.statistics.today.uv}}<br>
          昨日：{{record.statistics.yesterday.uv}}
        </template>

        <template slot="jump" slot-scope="data,record">
          今日：{{record.statistics.today.jump}}%<br>
          昨日：{{record.statistics.yesterday.jump}}%
        </template>

        <template slot="deep" slot-scope="data,record">
          今日：{{record.statistics.today.deep}}<br>
          昨日：{{record.statistics.yesterday.deep}}
        </template>
        <template slot="order_count" slot-scope="data,record">
          今日：{{record.statistics.today.order_count}}<br>
          昨日：{{record.statistics.yesterday.order_count}}
        </template>
        <!-- 操作单元格 -->
        <template slot="操作" slot-scope="data">
              <span>
<!--                <a-button size="small" type="primary" @click="handleView(data.site_id)">访问明细</a-button>-->
                  <a-button size="small" type="primary" @click="handleView(data.site_id)">查看来路</a-button>
              </span>

        </template>
<!--        &lt;!&ndash; 额外展开的行 &ndash;&gt;-->
<!--        <a-table-->
<!--            class="noBorder"-->
<!--            slot="expandedRowRender"-->
<!--            :size="'small'"-->
<!--            slot-scope="record"-->
<!--            :columns="innerColumns"-->
<!--            :data-source="record.list"-->
<!--            :pagination="false"-->
<!--        >-->
<!--        </a-table>-->
<!--        &lt;!&ndash; 额外展开的行结束 &ndash;&gt;-->
      </a-table>
    </div>

    <a-modal
        title="编辑备注"
        :visible="editModalVisible"
        :confirm-loading="confirmLoading"
        @ok="save"
        @cancel="cancel"
    >
      <!-- 编辑 -->
      <a-form-model
          ref="editRef"
          :model="editData"
          :label-col="{span:7}"
          :wrapper-col="{span:17}"
      >
        <a-form-model-item label="备注">
          <a-input v-model="editData.remark" placeholder="请输入备注..."/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

  </div>
</template>
<script>
import  {trafficStatistics,editRemark} from "@/axios/stats.js"
import {myMixin} from "@/static/js/mixin.js"
import moment from "moment";
const columns = [//列描述
  {
    align:'center',
    title: '网站ID',
    dataIndex: 'site_id',
    // sorter: (a, b) => a.site_id - b.site_id,
  },
  {
    align:'center',
    title: '备注',
    scopedSlots: { customRender: '备注', },
  },
  {
    align:'center',
    title: '网站域名',
    dataIndex: 'domain',
  },
  {
    align:'center',
    title: '访问量(IP)',
    dataIndex: 'ip_count',
    scopedSlots: { customRender: 'IP', },
    sorter: (a, b) => a.statistics.today.ip_count - b.statistics.today.ip_count,
    // sorter:function(IP){
    //
    // },
    // sorter: (a, b) => {
    //   console.log(a, b,'1')
    //   // console.log(1)
    //   // let prev = a.abName ? a.abName : '';
    //   // let next = b.abName ? b.abName : '';
    //   // return prev.localeCompare(next, 'zh-Hans-CN', { sensitivity: 'accent' });
    // },
    // sorter: (a, b) => a.statistics.today.ip_count + a.statistics.yesterday.ip_count - b.statistics.today.ip_count - b.statistics.yesterday.ip_count,
  },
  {
    align:'center',
    title: '浏览量(PV)',
    dataIndex: 'PV',
    scopedSlots: { customRender: 'PV', },
  },
  {
    align:'center',
    title: '访客数(UV)',
    dataIndex: 'UV',
    scopedSlots: { customRender: 'UV', },
  },
  {
    align:'center',
    title: '跳出率',
    dataIndex: 'jump',
    scopedSlots: { customRender: 'jump', },
  },
  {
    align:'center',
    title: '浏览深度',
    dataIndex: 'deep',
    scopedSlots: { customRender: 'deep', },
  },
  {
    align:'center',
    title: '订单数',
    dataIndex: 'order_count',
    scopedSlots: { customRender: 'order_count', },
  },
  {
    align:'center',
    title: '操作',
    // dataIndex: 'status',
    scopedSlots: { customRender: '操作', },
  },
];
export default {
  filters: {

  },
  mixins: [myMixin],
  data() {

    return {

      languagesList:[],
      form: {
        page_size: 50,
        page:1,
        domain:'',
        date:'',
        domain_type:'',
      },
      domainTypeList: [
        {
          value: "",
          label: '全部'
        },
        {
          value: "a",
          label: '仅A站'
        },
        {
          value: "b",
          label: '仅B站'
        }
      ],

      columns,//列描述

      confirmLoading:false,//编辑模态框
      editModalVisible:false,
      editForm:{
        domain:'',
      },
      editFormRules: {
        domain: [
          { required: true, message: '请输入网站域名', trigger: 'blur' },
        ],
      },
      title:'编辑ES',
      editData:{
        remark:'',
        sites_id:'',
      },

      // totalIp:0,
      // totalPv:0,
      res_all:[],
      // languagesList:[]
    }

  },
created() {
this.form.date =moment().format('YYYY-MM-DD');
this.form.domain_type = 'a';
},


  mounted() {


    let that=this

    this.fetch();

  },

  methods:{
  	moment,
	  onChangeDate(date, dateString) {//时间
      this.form.date=dateString;
   	},

    async fetch (params = {}) {
      // this.totalIp=0;
      // this.totalPv=0;
      this.res_all = [];

      this.loading = true;
      let data=await trafficStatistics({...params,...this.form})

      this.loading = false;
      const pagination = { ...this.pagination };
      pagination.total = data.data.total;
      this.data = data.data.list;
      this.res_all = data.data.res_all;
      // this.totalIp=data.data.ip_total;
      // this.totalPv=data.data.pv_total
      this.pagination = pagination;
    },


    handleView(ID){
      this.$router.push({path:'/source',query:{
          site_id:ID,
        // time:this.form.date
        }})
    },
    //编辑保存用户数据
    onEdit(value,Id){
      this.editData = {
        remark:value,
        sites_id:Id,
      }
      this.editModalVisible=true;
    },
    async save(params = {}){
      let editA = await editRemark({...params,...this.editData})
      this.editModalVisible = false;
      this.fetch()
    },
    cancel(){

      this.editModalVisible = false;
    },
    handleClickRow(){
      console.log(1)
    },
    handleTableChange(pagination, filters, sorter) {
      console.log(sorter,'sorter')
      //pagination:分页信息，filters：筛选信息，sorter：排序信息
      this.pagination = { ...pagination };
      this.form.page_size=pagination.pageSize
      this.form.page=pagination.current
      console.log(this.pagination,pagination)

      this.fetch({
        order:sorter.order||'',
        field:sorter.field||'',
        ...this.form
      });
    },

  }
};
</script>
<style lang="scss" scoped>
@import "@/static/css/index.scss";
.ant-table td { white-space: nowrap; }
.ant-table td .greenBtn{background-color:#7ac141;border-color:#7ac141;}
</style>

